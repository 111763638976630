import React from "react";
import Layout from "../components/layout";
import InGrid from "../components/in-grid";



const VideoPage = () => (
  <Layout currentPage="videopage">
      <InGrid columns={12}>
    <div className="container" style={{paddingLeft:"12px", paddingRight:"12px", paddingBottom: "100px", paddingTop: "100px", maxWidth: "100vw", display: "block"}}>

    <div style={{width: "100%", height: "0px", position: "relative", paddingBottom: "56.250%"}}><iframe src="https://player.vimeo.com/video/348894637" frameborder="0" width="100%" height="100%" allowfullscreen style={{width: "100%", height: "100%", position: "absolute", left: "0px", top: "0px", overflow: "hidden"}}></iframe></div>   

</div>
      </InGrid>
      

  </Layout>
);

export default VideoPage;
